@font-face {
  font-family: 'Lato Black';
  src: url(/public/assets/fonts/Lato/Lato-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato Regular';
  src: url(/public/assets/fonts/Lato/Lato-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato Bold';
  src: url(/public/assets/fonts/Lato/Lato-Bold.ttf) format('truetype');
}

